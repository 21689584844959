<template>
  <v-layout>
    <v-navigation-drawer
      width="360"
      fixed
      bottom
      persistent
      class="pa-8 pt-16"
      style="z-index: 1"
    >
      <v-select
        label="Forecast wählen"
        v-model="selected.forecast"
        :items="forecasts.filter(f => f.vis)"
        item-value="id"
        item-text="title"
        item-disabled="disabled"
        class="pt-8"
        @change="setForecast"
      ></v-select>

      <v-select
        label="Snapshot wählen"
        v-model="selected.snapshot"
        :items="snapshots"
        item-value="id"
        item-text="title"
      ></v-select>
      <v-menu
        v-if="selected.snapshot === null"
        v-model="datePicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="localeDate"
            label="Wähle ein Datum"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="selected.until"
          :locale="$store.state.me.locale"
          first-day-of-week="1"
          :max="maxDate"
          color="primary"
          header-color="primary"
          @input="datePicker = false"
        ></v-date-picker>
      </v-menu>

      <h4><v-icon small>mdi-filter</v-icon><span>Filterkriterien</span></h4>
      <v-select
        label="alle Units"
        v-model="selected.divisions"
        :items="userDivisions"
        item-value="id"
        item-text="name"
        multiple
      ></v-select>

      <v-select
        label="alle Kunden"
        v-model="selected.clients"
        :items="clientsWithBudget"
        item-value="id"
        item-text="name"
        multiple
      ></v-select>

      <v-select
        label="alle Gattungen und Services"
        v-model="selected.attributes"
        :items="attributes"
        item-value="id"
        item-text="value"
        multiple
      ></v-select>

      <h4>Budgetstatus (alle oder nur ausgewählte)</h4>
      <v-checkbox
        v-for="n in $store.state.budget.status"
        :key="n.label"
        :label="n.label"
        :value="n.value"
        :color="n.color"
        v-model="selected.status"
        dense
        :ripple="false"
      ></v-checkbox>

      <v-select
        label="Fremdkosten"
        v-model="selected.costs"
        :items="costs"
        item-value="value"
        item-text="label"
        outlined
        dense
        class="mt-8"
      ></v-select>

      <v-select
        label="AE"
        v-model="selected.ae_filter"
        :items="ae"
        item-value="value"
        item-text="label"
        outlined
        dense
      ></v-select>

      <v-select
        label="Honorarbasis"
        v-model="selected.honorar_basis_field"
        :items="honorarbasis"
        item-value="value"
        item-text="label"
        outlined
        dense
      ></v-select>

      <v-row>
        <v-col cols="5">
          <v-select
            v-model="selected.queryfield.key"
            :items="queryFields"
            item-value="value"
            item-text="label"
            outlined
            dense
            @change="resetQueryfield"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            :items="comparisonOperators"
            item-value="value"
            item-text="label"
            v-model="selected.queryfield.co"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field outlined dense v-model="selected.queryfield.value" />
        </v-col>
      </v-row>

      <v-btn
        color="primary"
        width="100%"
        @click="getTotals"
        :disabled="!selected.forecast"
        >Zahlen abrufen</v-btn
      >
    </v-navigation-drawer>

    <v-container fluid style="padding-left:400px;padding-right:40px">
      <v-row class="mb-6">
        <h1 class="mt-6 ml-3">{{ trl("FC_TENANT_READ_menu") }}</h1>
        <v-spacer></v-spacer>

        <ForecastTools class="mt-8 mr-8" />
      </v-row>
      <v-row 
     
        v-if="myReports.length === 0"
        style="padding-top:25vh"
        align="center"
        justify="center">
        <v-icon size="200" color="grey lighten-1">mdi-database-search</v-icon>
    </v-row>

      <v-card class="mb-3" v-for="rep in myReports" :key="rep.id">
        <v-card-title>
          #{{ rep.id }} {{ rep.params.fcid }} &nbsp;
          <span v-if="rep.params.snapshot"
            >Snapshot {{ rep.params.snapshot }}</span
          >
          <span v-else> Stand: {{ rep.params.until }}</span>
          <v-spacer> </v-spacer>
          <Download
            v-if="current.permissions.FC_TENANT_EXPORT"
            :query="rep.query"
          />

          <v-btn icon @click="removeItem(rep.id)"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-card-title>

        <v-card-subtitle>
          <v-chip
            small
            color="primary lighten-3"
            class="mr-2"
            v-for="(item, name) in rep.params.filter"
            :key="name"
          >
            {{ smartjoin(item, trl("and")) }}
          </v-chip>
          <div v-if="Object.keys(rep.params.filter).length === 0">
            Gesamt (keine Filter)
          </div>
        </v-card-subtitle>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="rep.totals"
            :hide-default-footer="true"
            class="mb-2"
          >
            <template v-slot:[`item.numDivisions`]="{ item }">
              <v-chip
                small
                @click="
                  rep.accumulated = sumsByColumn(
                    rep.data,
                    'numDivisions',
                    rep.totals[0].total_wo_fk
                  )
                "
                >{{ item.numDivisions }}</v-chip
              >
            </template>

            <template v-slot:[`item.numClients`]="{ item }">
              <v-chip
                small
                @click="
                  rep.accumulated = sumsByColumn(
                    rep.data,
                    'numClients',
                    rep.totals[0].total_wo_fk
                  )
                "
                >{{ item.numClients }}</v-chip
              >
            </template>

            <template v-slot:[`item.numAttributes`]="{ item }">
              <v-chip
                small
                @click="
                  rep.accumulated = sumsByColumn(
                    rep.data,
                    'numAttributes',
                    rep.totals[0].total_wo_fk
                  )
                "
                >{{ item.numAttributes }}</v-chip
              >
            </template>

            <template v-slot:[`item.numStatus`]="{ item }">
              <v-chip
                small
                @click="
                  rep.accumulated = sumsByColumn(
                    rep.data,
                    'numStatus',
                    rep.totals[0].total_wo_fk
                  )
                "
                >{{ item.numStatus }}</v-chip
              >
            </template>
          </v-data-table>
          <v-data-table
            v-if="rep.accumulated.length > 0"
            dense
            dark
            :headers="headers2"
            :items="rep.accumulated"
            :items-per-page="15"
            :hide-default-footer="rep.accumulated.length <= 15"
            :footer-props="{
              itemsPerPageOptions: [15, 20, 25, 50, -1],
              itemsPerPageText: 'Zeilen pro Seite',
            }"
            class="elevation-4"
            :fixed-header="true"
            :sort-by="['label']"
          >
            <template v-slot:[`item.kundennetto`]="{ item }">
              <span :class="{ zero: item.kundennetto === 0 }">
                <span>{{ toLocale(item.kundennetto) }}</span
                >&nbsp;<span>T€</span></span
              >
            </template>

            <template v-slot:[`item.agenturnetto`]="{ item }">
              <span :class="{ zero: item.agenturnetto === 0 }">
                <span>{{ toLocale(item.agenturnetto) }}</span
                >&nbsp;<span>T€</span></span
              >
            </template>

            <template v-slot:[`item.income_other`]="{ item }">
              <span :class="{ zero: item.income_other === 0 }">
                <span>{{ toLocale(item.income_other) }}</span
                >&nbsp;<span>T€</span></span
              >
            </template>

            <template v-slot:[`item.honorar_basis`]="{ item }">
              <span :class="{ zero: item.honorar_basis === 0 }">
                <span>{{ toLocale(item.honorar_basis) }}</span
                >&nbsp;<span>T€</span></span
              >
            </template>

            <template v-slot:[`item.honorar_sokos`]="{ item }">
              <span :class="{ zero: item.honorar_sokos === 0 }">
                <span>{{ toLocale(item.honorar_sokos) }}</span
                >&nbsp;<span>T€</span></span
              ></template
            >

            <template v-slot:[`item.total`]="{ item }">
              <span :class="{ zero: item.total === 0 }">
                <span>{{ toLocale(item.total) }}</span
                >&nbsp;<span>T€</span></span
              >
            </template>
            <template v-slot:[`item.fremdkosten`]="{ item }">
              <span :class="{ zero: item.fremdkosten === 0 }">
                <span>{{ toLocale(item.fremdkosten) }}</span
                >&nbsp;<span>T€</span></span
              >
            </template>

            <template v-slot:[`item.total_wo_fk`]="{ item }">
              <span :class="{ zero: item.total_wo_fk === 0 }">
                <span>{{ toLocale(item.total_wo_fk) }}</span
                >&nbsp;<span>T€</span></span
              >
            </template>

            <template v-slot:[`item.percent`]="{ item }">
              <span :class="{ zero: item.percent === 0 }">
                <span>{{ toLocale(item.percent) }}</span
                >&nbsp;<span>%</span></span
              >
            </template>

            <template slot="body.append" v-if="rep.accumulated.length > 1">
              <tr class="black white--text">
                <th
                  v-for="(header, index) in headers2"
                  :key="header.value"
                  class="text-end"
                  style="font-size: 14px"
                >
                  <span v-if="index > 0 && index < headers2.length - 1"
                    >{{ rep.totals[0][header.value] }} T€</span
                  >
                  <span v-if="index === headers2.length - 1"
                    >{{ toLocale(100) }} %</span
                  >
                </th>
              </tr>
            </template>

            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} - {{ items.pageStop }} {{ trl("of") }}
              {{ items.itemsLength }}
            </template>
          </v-data-table>
          <v-row>
            <caption v-if="rep.accumulated.length > 0" class="ma-6">
              * "Anteil in %" bezogen auf
              <strong>Gesamt ./. FK</strong>
            </caption>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
import store from "@/store";
import { trl, smartjoin, getDivisionName, getClientName, getAttributeName } from "@/utils/strings";
import { toLocale, fromLocale } from "@/utils/numbers";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import PieChart from "@/components/PieChart";
import Download from "@/components/modals/Download";
import ForecastTools from "@/components/ForecastTools";

export default {
  data() {
    return {
      datePicker: false,
      maxDate: DateTime.now().toISODate(),
      headers: [
        {
          text: trl("budget_numDivisions"),
          value: "numDivisions",
          align: "center",
        },
        {
          text: trl("budget_numClients"),
          value: "numClients",
          align: "center",
        },
        {
          text: trl("budget_numAttributes"),
          value: "numAttributes",
          align: "center",
        },
        { text: trl("budget_numStatus"), value: "numStatus", align: "center" },
      ],
      headers2: [
        { text: "Name", value: "label", class: "primary white--text" },
      ],
      clientsWithBudget: [],
      ae: [
        { label: "kein Filter", value: null },
        { label: "mit AE", value: "yes" },
        { label: "ohne AE", value: "no" },
      ],
      costs: [
        { label: "kein Filter", value: null },
        { label: "enthält keine Fremdkosten", value: "no" },
        { label: "enthält Fremdkosten", value: "yes" },
        { label: "enthält interne Fremdkosten", value: "INT" },
        { label: "enthält externe Fremdkosten", value: "EXT" },
      ],
      honorarbasis: [
        { label: "kein Filter", value: null },
        { label: "KN - Kundennetto", value: "kundennetto" },
        { label: "AN - Agenturnetto", value: "agenturnetto" },
      ],
      comparisonOperators: [
        { label: "=", value: "equal_to" },
        { label: "!=", value: "not_equal_to" },
        { label: ">", value: "greater_than" },
        { label: ">=", value: "greater_than_or_equal_to" },
        { label: "<", value: "less_than" },
        { label: "<=", value: "less_than_or_equal_to" },
      ],
      selected: {
        forecast: null,
        snapshot: null,
        until: DateTime.now().toFormat("yyyy-MM-dd"),
        divisions: [],
        clients: [],
        attributes: [],
        status: [],
        ae_filter: null,
        costs: null,
        honorar_basis_field: null,
        queryfield: {
          key: null,
          co: null,
          value: null,
        },
      },
      myReports: [],
    };
  },
  computed: {
    ...mapGetters([
      "current",
      "clients",
      "divisions",
      "userClients",
      "userDivisions",
      "forecasts",
      "attributes",
    ]),
    snapshots() {
      let data = [];

      this.selected.snapshot = null;

      if (this.selected.forecast) {
        let snapshots = this.forecasts.find(
          (f) => f.id === this.selected.forecast
        ).snapshots;
        snapshots.forEach((s) => {
          if (s.closed_at) {
            this.selected.snapshot = s.id;
          }

          let str_close = s.closed_at ? "" : " (noch nicht geschlossen)";

          data.push({
            id: s.id,
            disabled: !s.closed_at,
            title: s.title + str_close,
          });
        });
      }

      data.push({
        id: null,
        disabled: false,
        title: "eigenes Datum...",
      });

      return data;
    },
    localeDate() {
      return this.selected.until
        ? DateTime.fromISO(this.selected.until)
            .setLocale(this.$store.state.me.locale)
            .toFormat("dd.LL.yy")
        : "";
    },
    queryFields() {
      let arr = [{ label: "---", value: null }];
      this.$store.state.budget.fields.forEach((f) => {
        arr.push({ label: trl("budget_" + f.key), value: f.key });
      });

      arr.push({ label: trl("budget_initials"), value: "initials" });

      return arr;
    },
  },
  methods: {
    trl,
    smartjoin,
    getDivisionName,
    getClientName,
    getAttributeName,
    toLocale,
    setForecast(){
        store.commit("setForecast",this.selected.forecast);
    },
    async getTotals() {
      let params = {
        tenant: this.current.id,
        fcid: this.selected.forecast,
      };

      if (this.selected.snapshot !== null) {
        params.snapshot = this.selected.snapshot;
      } else {
        params.until = this.selected.until + " 23:59:59";
      }

      if (this.selected.divisions.length > 0) {
        params.division = this.selected.divisions;
      }

      if (this.selected.clients.length > 0) {
        params.client = this.selected.clients;
      }

      if (this.selected.status.length > 0) {
        params.status = this.selected.status;
      }

      if (this.selected.attributes.length > 0) {
        params.attributes = this.selected.attributes;
      }

      if (this.selected.costs) {
        params.costs = this.selected.costs;
      }

      if (this.selected.ae_filter) {
        params.ae_filter = this.selected.ae_filter;
      }

      if (this.selected.honorar_basis_field) {
        params.honorar_basis_field = this.selected.honorar_basis_field;
      }

      if (
        this.selected.queryfield.key &&
        this.selected.queryfield.co &&
        this.selected.queryfield.value
      ) {
        params[this.selected.queryfield.key] = [
          this.selected.queryfield.co,
          this.selected.queryfield.value,
        ];
      }

      const data = await this.$store.dispatch("req", {
        route:
          "explore?" +
          Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&"),
      });

      // Summerien

      let sums = {};
      this.$store.state.budget.fields.forEach((f) => {
        if (!f.sumup) return;
        sums[f.key] = 0;
      });

      data.forEach((row) => {
        const budget = row.budget;
        this.$store.state.budget.fields.forEach((f) => {
          if (!f.sumup) return;
          sums[f.key] += budget[f.key];
        });
      });

      let costItems = [].concat(
        ...data
          .filter((row) => row.budget.costs)
          .map((item) => item.budget.costs)
      );
      sums.fk_int = costItems
        .filter((item) => item.type === "INT")
        .reduce((accumulator, current) => accumulator + current.value, 0);
      sums.fk_ext = costItems
        .filter((item) => item.type === "EXT")
        .reduce((accumulator, current) => accumulator + current.value, 0);

      for (let i in sums) {
        sums[i] = toLocale(sums[i]);
      }

      sums.numDivisions = new Set([...data.map((row) => row.division_id)]).size;
      sums.numClients = new Set([
        ...data.map((row) => row.budget.client_id),
      ]).size;
      sums.numAttributes = new Set([
        ...data.map((row) => row.budget.attribute_id),
      ]).size;
      sums.numStatus = new Set([...data.map((row) => row.budget.status)]).size;

      this.myReports.push({
        id: this.myReports.length + 1,
        query: params,
        params: this.humanReadable(params),
        data: data,
        totals: [sums],
        accumulated: [],
      });
      this.$store.commit("setExplorer", this.myReports);
    },
    humanReadable(params) {
      // create labels for Filter Chips in 2nd row of v-card
      let filter = {};

      if (params.division) {
        filter.division = params.division.map(
          (x) => this.getDivisionName(x)
        );
      }

      if (params.client) {
        filter.client = params.client.map(
          (x) => this.getClientName(x)
        );
      }

      if (params.attributes) {
        filter.attributes = params.attributes.map(
          (x) => this.getAttributeName(x)
        );
      }

      if (params.status) {
        filter.status = params.status.map(
          (x) => this.$store.state.budget.status.find((y) => y.value == x).label
        );
      }

      if (params.costs) {
        filter.costs = [this.costs.find((x) => x.value === params.costs).label];
      }

      if (params.ae_filter) {
        filter.ae_filter = [
          this.ae.find((x) => x.value === params.ae_filter).label,
        ];
      }

      if (params.honorar_basis_field) {
        filter.honorar_basis_field = [
          this.honorarbasis.find((x) => x.value === params.honorar_basis_field)
            .label,
        ];
      }

      if (
        this.selected.queryfield.key &&
        this.selected.queryfield.co &&
        this.selected.queryfield.value
      ) {
        let tmp = [];
        tmp.push(trl("budget_" + this.selected.queryfield.key));
        tmp.push(
          this.comparisonOperators.find(
            (c) => c.value === this.selected.queryfield.co
          ).label
        );
        tmp.push('"' + this.selected.queryfield.value + '"');
        filter.queryfield = [tmp.join(" ")];
      }

      let obj = {
        fcid: this.forecasts.find((item) => item.id === params.fcid).title,
        filter: filter,
      };

      if (params.snapshot) {
        obj.snapshot = this.snapshots.find(
          (s) => s.id === params.snapshot
        ).title;
      } else {
        obj.until = this.formatDate(params.until);
      }

      return obj;
    },
    sumsByColumn(data, key, total_wo_fk) {
      this.headers2[0].text = trl("budget_" + key);

      let mySet, rows, label;
      let totals = [];

      switch (key) {
        case "numDivisions":
          mySet = [...new Set(data.map((b) => b.division_id))];
          break;
        case "numClients":
          mySet = [...new Set(data.map((b) => b.budget["client_id"]))];
          break;
        case "numAttributes":
          mySet = [...new Set(data.map((b) => b.budget["attribute_id"]))];
          break;
        case "numStatus":
          mySet = [...new Set(data.map((b) => b.budget["status"]))];
          break;
      }

      mySet.forEach((id) => {
        switch (key) {
          case "numDivisions":
            rows = data.filter((row) => row.division_id === id);
            label = this.getDivisionName(id);
            break;
          case "numClients":
            rows = data.filter((row) => row.budget.client_id === id);
            label = this.getClientName(id);
            break;
          case "numAttributes":
            rows = data.filter((row) => row.budget.attribute_id === id);
            label = this.getAttributeName(id);
            break;
          case "numStatus":
            rows = data.filter((row) => row.budget.status === id);
            label =
              id +
              ". " +
              this.$store.state.budget.status.find((item) => item.value === id)
                .label;
            break;
        }

        let values = {
          label: label,
        };

        this.$store.state.budget.fields.forEach((f) => {
          values[f.key] = rows.reduce(
            (accumulator, current) =>
              accumulator + (current.budget[f.key] || 0),
            0
          );
        });

        values.percent =
          (100 / fromLocale(total_wo_fk)) * values["total_wo_fk"];

        totals.push(values);
      });

      return totals;
    },
    removeItem(id) {
      let index = this.myReports.findIndex((item) => item.id === id);
      this.myReports.splice(index, 1);
      this.$store.commit("setExplorer", this.myReports);

    },
    formatDate(ts) {
      return DateTime.fromSQL(ts)
        .setLocale(this.$store.state.me.locale)
        .toFormat("dd.LL.yy");
    },
    init() {
      (this.selected.forecast = this.forecasts.filter(
        (f) => f.id == this.$store.state.activeForecastId
      )[0]?.id),
        (this.clientsWithBudget = this.userClients().filter(
          (c) => c.divisions || c.id === 0
        ));

      this.myReports = this.$store.state.explorer.reports;
      this.selected.clients = [];
      this.selected.divisions = [];
    },
    resetQueryfield() {
      if (this.selected.queryfield.key) return;
      this.selected.queryfield.co = null;
      this.selected.queryfield.value = null;
    },
  },
  created() {

    this.$store.state.budget.fields.forEach((f) => {
      if (!f.sumup) return;
      this.headers.push({
        text: trl("budget_" + f.key),
        value: f.key,
        align: "end",
      });

      this.headers2.push({
        text: trl("budget_" + f.key),
        value: f.key,
        align: "end",
        class: "primary white--text",
      });
    });

    this.headers2.push({
      text: "Anteil in %*",
      value: "percent",
      align: "end",
      class: "primary white--text",
    });

    this.init();
  },
  components: { PieChart, Download, ForecastTools },
  beforeRouteUpdate(to, from, next) {
    this.init();
    next();
  },
  beforeRouteEnter(to, from, next) {

    if (store.getters.current.permissions.FC_TENANT_READ) {
      store.state.ui.hideForecastSelector = true;
      next();
    } else {
      next({ name: "NotFound" });
    }
  },
   beforeRouteLeave(to, from, next) {
    store.state.ui.hideForecastSelector = false;
    next();
  }
};
</script>

<style lang="scss">
.v-input--checkbox > .v-input__control {
  height: 1rem !important;
  font-size: 12px;
}

.col .pie {
  width: 28px;
  height: 28px;
  margin-bottom: -2px;
  border: none;
  box-shadow: none;
}

.zero {
  opacity: 0.25;
}
</style>